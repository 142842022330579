import React, { useEffect } from 'react';
import './App.css';

function App() {


  function navigateTo(url) {
    window.location.href = url;
  }

  return (

  <div className="App">

    <div class="container">
        <h1> WORK IN PROGRESS</h1>
        <h2> Sorry.. all the wobblecatz are sleeping...</h2>
        <button onClick={() => navigateTo("https://linkinbio.wobblecatz.com")}>
          CLICK TO FIND OUT MORE ABOUT WOBBLECATZ
        </button>
    </div>

    </div>
  );
}

export default App;